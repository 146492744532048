
import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { DataDictionaryDto, InternalMicrocosmicDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

import echarts from "echarts";
import "echarts/lib/chart/map";
import "echarts/map/js/china.js";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";

@Component({
  name: "InternalDashboard",
  components: {
    PagedTableView
  },
  filters: {
    formatWorkflowStep(status: string, list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map(item => {
          if (item.value == status) {
            result = item.text;
            return;
          }
        });
      }
      return result;
    }
  }
})
export default class InternalDashboard extends Vue {
  activeName: string = "first";

  statusList = [
    {
      value: "ProjectApproval",
      label: "立项完成"
    },

    {
      value: "Closed",
      label: "结项完成"
    },

    {
      value: "Termination",
      label: "项目终止"
    }
  ];

  projectTypeList: DataDictionaryDto[] = [];
  unitTypeList: DataDictionaryDto[] = [];
  microData: InternalMicrocosmicDto[] = [];

  queryData: {} = {
    projectType: "",
    year: "",
    status: "",
    maxResultCount: 1000
  };

  yearData: number[] = [];
  projectCounts: number[] = [];
  activityCounts: number[] = [];
  actualBeneficiaryCounts: number[] = [];
  actualExpenses: number[] = [];
  totalBudgets: number[] = [];
  townCounts: number[] = [];
  workflowStepList: any = [];

  option: any = {
    title: {
      text: "宏观展示"
    },
    tooltip: {
      trigger: "axis"
    },
    legend: {
      data: [
        "项目数量",
        "实际受益人次",
        "活动场次",
        "实际覆盖区域数量",
        "预算金额",
        "实际支出"
      ]
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    toolbox: {
      feature: {
        //saveAsImage: {}
      }
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: this.yearData
    },
    yAxis: [
      {
        type: "value",
        name: "数量/次",
        position: "left",
        axisLabel: {
          formatter: "{value}"
        }
      },
      {
        type: "value",
        name: "金额",
        position: "right",
        axisLabel: {
          formatter: "{value} （元）"
        }
      }
    ],
    series: [
      {
        name: "项目数量",
        type: "line",
        //stack: "总量",
        //yAxisIndex: 1,
        data: this.projectCounts
      },
      {
        name: "实际受益人次",
        type: "line",
        //stack: "总量",
        //yAxisIndex: 1,
        data: this.actualBeneficiaryCounts
      },
      {
        name: "活动场次",
        type: "line",
        //stack: "总量",
        //yAxis: 1,
        data: this.activityCounts
      },
      {
        name: "实际覆盖区域数量",
        type: "line",
        //stack: "总量",
        //yAxis: 1,
        data: this.townCounts
      },
      {
        name: "预算金额",
        type: "line",
        //stack: "总量",
        yAxisIndex: 1,
        data: this.totalBudgets
      },
      {
        name: "实际支出",
        type: "line",
        //stack: "总量",
        yAxisIndex: 1,
        data: this.actualExpenses
      }
    ]
  };

  mounted() {
    //
    api.internalProjectReport.getMacroscopic().then(res => {
      this.yearData = res.years!;
      //this.yearData.push(2021);
      this.option.xAxis.data = this.yearData;

      this.activityCounts = res.activityCounts!;
      //this.activityCounts.push(20);
      this.option.series[2].data = this.activityCounts;

      this.projectCounts = res.projectCounts!;
      //this.projectCounts.push(20);
      this.option.series[0].data = this.projectCounts;

      this.actualBeneficiaryCounts = res.actualBeneficiaryCounts!;
      //this.actualBeneficiaryCounts.push(20);
      this.option.series[1].data = this.actualBeneficiaryCounts;

      this.actualExpenses = res.actualExpenses!;
      //this.actualExpenses.push(20);
      this.option.series[5].data = this.actualExpenses;

      this.totalBudgets = res.totalBudgets!;
      //this.totalBudgets.push(20);
      this.option.series[4].data = this.totalBudgets;

      this.townCounts = res.townCounts!;
      //this.townCounts.push(20);
      this.option.series[3].data = this.townCounts;
      //
      const myChart = echarts.init(this.$refs.echartGeo as HTMLDivElement);
      myChart.setOption(this.option);
    });
  }

  handleClick() {}

  handleSearch() {
    this.getMicrocosmics();
  }
  handleReset() {
    this.queryData = {
      projectType: "",
      year: "",
      status: "",
      maxResultCount: 1000
    };
    this.getMicrocosmics();
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "WorkflowOfProjectByInternal" })
      .then(res => {
        this.workflowStepList = res;
      });
  }

  created() {
    this.fetchEnum();
    this.initDefaultData();
  }

  initDefaultData() {
    //项目类别
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "ProjectType" })
      .then(res => {
        this.projectTypeList = res.items!;
      });
    //项目类别
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "OrganizationUnitType" })
      .then(res => {
        this.unitTypeList = res.items!;
      });
    //
    this.getMicrocosmics();
  }

  getMicrocosmics() {
    //
    api.internalProjectReport.getMicrocosmics(this.queryData).then(res => {
      this.microData = res.items!;
      console.log(this.microData);
    });
  }

  //未完成月度计划项目列表
  queryForm1 = {
    year: undefined
  };
  fetchData1(params: any) {
    return api.internalProjectReport.getNotCompleteMonthlySummaryProjects(
      params
    );
  }
  //没有正常关项的项目列表
  queryForm2 = {
    year: undefined
  };
  fetchData2(params: any) {
    return api.internalProjectReport.getNotClosingNormallyProjects(params);
  }
}
